import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Russia = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta
          name="twitter:title"
          content="Should the U.S. Go to War with Russia?"
        />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Should the U.S. Go to War with Russia?</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Jan. 24, 2022) </p>
        </div>
        <div className="articleBody">
          <p>Ahhh, a new year. Welcome! </p>
          <p>Let's think about a word: technology.</p>
          <p>
            What comes to mind for you? Smartphones? Laptops? Tablets?
            Hoverboards? Heated toilets with bidets that speak Japanese? V12
            engines that shatter Mach speed with boring regularity? Air fryers?
            Those fans without blades? The Boston Dynamics creatures? Sewing
            machines? <i>I, Robot</i> (2004)? Bose speakers? JBL speakers?
            Harman Kardon speakers? Snow shoes? Snow tires? Augmented reality
            contact lenses? Connectomes? Moog synthesizers? Comprehensive blood
            panels? The particle colliders that they be using to discover new
            particles? When you smash two particles together, you can look and
            be like oh wow new particle? Turn to your colleague and be like ‘new
            particle!!!’? The Canon 1DX II with the 85mm L-series lens that they
            use to take your photo at the Nobel ceremony? ICBM’s? 1 PB SSD’s?
            Lasers that tell you how far away some shit is (better than tape
            measures)? Tape measures? SSRI’s? Tamagotchis? Node.js? Aqueducts?
            The Burj Khalifa?
          </p>
          <p>
            But wait lemme figure out how to make my point now I don’t mean to
            waste your precious time my friend okay lmaooo uhhhhhhhhh.....
          </p>
          <p>
            So what’s the common denominator between all the technologies listed
            above? They’re all made manifest through engineering. And
            engineering is just applied science and mathematics. But science and
            mathematics are really just collections of <i>ideas</i>. And so: a
            technology is something useful that results from the application of
            some combination of sensical ideas. And like the organism(
            <a
              href="https://www.thoughtco.com/11-amazing-animals-that-use-tools-4125950"
              target="__blank"
              rel="noopener noreferrer"
            >
              s
            </a>
            ) that creates and deploys them, a key feature of technologies is
            that they’re subject to evolution.
          </p>
          <p>
            Okay but watch this curveball lmao ready? Economic systems,
            therefore, qualify as technologies.
          </p>
          <p>
            I’ll spell it out. Are economies useful? Yeah, when we organize the
            ways we produce and distribute resources throughout a society,
            mortality rates go down and quality of life goes up. Do economic
            systems evolve? Well, 700 years of feudalism culminated in the
            Renaissance, then 200 years of capitalism took the US from some
            renegade colonies to the wealthiest society in human history to
            date, and most recently, 70 years of (
            <a
              href="https://www.youtube.com/watch?v=37nwLhIA1zs"
              target="__blank"
              rel="noopener noreferrer"
            >
              quick, click this
            </a>
            ) <i>communism</i> in Russia and China delivered those nations from
            abject poverty to launching the world’s first artificial satellite
            (it only took 40 years for this to happen technically, quick
            turnaround from pissing icicles in Siberia) and leading the entire
            world in GDP ($27T, compared to the U.S.’ $23T), respectively. In
            other words, the latest economic tech allows societies to develop at
            warp speed when compared to any we’ve ever seen 🤪
          </p>
          <p>
            Usually I would add a bunch of links and shit to corroborate what
            I’m saying but on this occasion imma leave it up to you to be
            rigorous and explore and confirm or deny and tease apart the truth
            from the falsity cuz that’s what life is about you lazy piece of
            shit I fucking love you!!!
          </p>
          <p>
            Oh wait, did you expect me to answer the question posed in the title
            of this article? Don’t worry, I wrote that article a couple years
            ago:{" "}
            <a
              href="https://youtu.be/RQ9BWndKEgs?t=82"
              target="__blank"
              rel="noopener noreferrer"
            >
              click this
            </a>
            , then{" "}
            <a
              href="http://shoya.co/shoyaright/vol1/us-iran"
              target="__blank"
              rel="noopener noreferrer"
            >
              this
            </a>
            .
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default Russia
